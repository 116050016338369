import React, { useState } from "react";
import ValidateForm from "./ValidateFormCita";
import validate from "./ValidateCita";
import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/es"; // the locale you want
import { setMinutes, setHours } from "date-fns";
import "./FormCita.css";
import "react-datepicker/dist/react-datepicker.css";
import GraciasCita from "./GraciasCita";
import { useEffect } from "react";
registerLocale("el", el); // register it with the name you want

const FormCita = ({ submitForm, modal }) => {
  const { handleChange, values, handleSubmit, errors, isLoading } =
    ValidateForm(submitForm, validate);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(true);

  function submitForm() {
    setIsSubmitted(true);
    setShowForm(false);
    const formSection = modal
      ? document.getElementsByClassName("show_cita_form--modal")[0]
      : document.getElementsByClassName("show_cita_form")[0];
    formSection.classList.add("hideFormSection");
  }

  const [startDate, setStartDate] = useState();

  // Función para generar horarios según el día
  const generateTimeSlots = (date) => {
    const day = date ? date.getDay() : null;
    const baseDate = date || new Date();
    const slots = [];
    let endHour = 17; // Default: Martes a Viernes

    if (day === 6) endHour = 18; // Sábados

    for (let hour = 10; hour < endHour; hour++) {
      [0, 15, 30, 45].forEach(minute => {
        slots.push(setHours(setMinutes(baseDate, minute), hour));
      });
    }
    return slots;
  };

  // Función para excluir domingos y lunes
  const isValidDay = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 1; // 0 = Domingo, 1 = Lunes
  };
  // Función de filtrado de tiempo
  const filterPassedTime = (time) => {
    const day = time.getDay();
    const hour = time.getHours();
    
    // Martes a Viernes: 10-17
    if (day >= 2 && day <= 5) {
      return hour >= 10 && hour < 17;
    }
    
    // Sábados: 10-18
    if (day === 6) {
      return hour >= 10 && hour < 18;
    }
    
    return false;
  };

  function keyUpHandler(elem, e, i) {
    elem.target.value = elem.target.value.replace(/[^0-9]/g, "");

    while (elem.target.value.charAt(0) === "0") {
      elem.target.value = elem.target.value.substr(1);
    }
    const tel = document.getElementById("phone");
    switch (elem.target.value.length) {
      case 2:
        tel.setAttribute("maxLength", 8);
        tel.setAttribute("minLength", 8);
        break;
      case 3:
        tel.setAttribute("maxLength", 7);
        tel.setAttribute("minLength", 7);
        break;
      case 4:
        tel.setAttribute("maxLength", 6);
        tel.setAttribute("minLength", 6);
        break;
      default:
        break;
    }
  }
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={`show_cita_form${modal ? "--modal" : ""}`}
      >
        <div className={`showroom-container${modal ? "--modal" : ""}`}>
          {!modal && (
            <div className="">
              <p className="title__form__cita">¡Agendá tu cita!</p>
              <div className="title__form__cita-info">
                <p>Visitá nuestro</p>
                <p className="title__form__cita-info2">Showroom de Casas</p>
              </div>
            </div>
          )}
          <div className="container-label">
            <div className="inputContainer">
              <input
                type="text"
                name="name"
                id="name"
                className="inputCita"
                placeholder="Nombre y Apellido"
                value={values.name}
                onChange={handleChange}
              />
              <label htmlFor="name" className="label">
                Nombre y Apellido
              </label>
            </div>
            {errors.name && (
              <small className="input-error">{errors.name}</small>
            )}
          </div>
          <div className="container-label">
            <div className="inputContainer">
              <input
                type="email"
                name="email"
                id="email"
                className="inputCita"
                placeholder="a"
                value={values.email}
                onChange={handleChange}
              />
              <label htmlFor="name" className="label">
                Email
              </label>
            </div>
            {errors.email && (
              <small className="input-error">{errors.email}</small>
            )}
          </div>

          <div className="grid-container">
            <div className="container-label">
              <div className="inputContainer">
                <input
                  type="tel"
                  name="area_code"
                  id="area_code"
                  className="inputCita"
                  placeholder="a"
                  value={values.area_code}
                  onKeyUp={keyUpHandler}
                  onChange={handleChange}
                />
                <label htmlFor="phone" className="label">
                  Cod. área
                </label>
              </div>
              {errors.area_code && (
                <small className="input-error">{errors.area_code}</small>
              )}
            </div>
            <div className="container-label">
              <div className="inputContainer">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  className="inputCita"
                  placeholder="a"
                  value={values.phone}
                  onChange={handleChange}
                />
                <label htmlFor="phone" className="label">
                  Teléfono
                </label>
              </div>
              {errors.phone && (
                <small className="input-error">{errors.phone}</small>
              )}
            </div>
          </div>

          <div className="container-label">
            <div className="inputContainer-disponibilidad inputContainer">
              <DatePicker
                className="inputCita"
                dateFormat="HH:mm - dd/MM/yyyy"
                timeFormat="HH:mm"
                selected={startDate}
                minDate={new Date()}
                placeholderText="Disponibilidad horaria"
                onFocus={(e) => e.target.blur(e)}
                onChange={(date) => {
                  setStartDate(date);
                }}
                showTimeSelect
                includeTimes={generateTimeSlots(startDate)}
                filterDate={isValidDay}
                timeIntervals={15}
                filterTime={filterPassedTime}
                strictParsing
                locale="el"
                timeCaption="Hora"
                popperPlacement="bottom-start"
                name="cita_date"
                id="cita_date"
                autoComplete="none"
              />
              <label htmlFor="cita_date" className="label_date">
                Disponibilidad
              </label>
            </div>
            {errors.cita_date && (
              <small className="input-error">{errors.cita_date}</small>
            )}
          </div>

          <div
            id="form-contain-btn"
            className={!modal ? "btnSubmit" : "btnModal"}
          >
            {isLoading ? (
              <button className="form-input-btn submitBtnLoading" type="submit">
                Enviando...
              </button>
            ) : (
              <button className="form-input-btn submitBtn" type="submit">
                {!modal ? "Enviar" : "Agendá tu cita"}
              </button>
            )}
          </div>
        </div>
      </form>
      {/* {true && <Gracias />} */}
      {!showForm && <GraciasCita modal={modal} />}
    </>
  );
};

export { FormCita };
